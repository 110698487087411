import "./Projects2.css"
import Project from "./Project";
import { FaArrowRightLong } from "react-icons/fa6";
import ProjectsList from "./ProjectsList";
import { Link } from "react-router-dom";

function Projects2() {
    return(
        <div id="Projects2">
            <h4>Projects</h4>
            <div className="projects-container">
                {ProjectsList.map((project) => {
                    console.log("projects: ", project)
                    return(
                        <Project
                            date={project.dates}
                            description={project.description}
                            title={project.title}
                            url={project.url}
                            tech={project.tech}
                        />
                    )
                })}
            </div>
            <Link to="/projects" className="extra"> View All <FaArrowRightLong className="arrow"/></Link>
        </div>
    )
}

export default Projects2;
