import { FaArrowRightLong } from "react-icons/fa6";
import "./Services.css";


function Services() {
    return(
        <div id="Services">
            <h4>Services</h4>
            <ul>
                <li>Website building</li>
                <li>Website maintenance</li>
            </ul>
            <div className="extra">Learn more <FaArrowRightLong className="arrow"/></div>
        </div>
    )
}

export default Services;
